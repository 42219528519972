import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";

import { ConfirmContextProvider } from "./app/components/confirmMessage/store/ConfirmContextProvider";
import ConfirmDialog from "./app/components/confirmMessage/ConfirmDialog";

import "./i18n";
import * as serviceWorker from "./serviceWorker";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';

import ContextState from "./app/core/store";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_URI}`,
  // uri: "https://app.proversaude.com.br/graphql",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('@MeuProverSaude/token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ addTypename: false }),
});

ReactDOM.render(
  <ContextState>
    <ApolloProvider client={client}>
      <ConfirmContextProvider>
        <BrowserRouter basename="/meu-prover-saude">
          <App />
        </BrowserRouter>
        <ConfirmDialog />
      </ConfirmContextProvider>
    </ApolloProvider>
    <ToastContainer />
  </ContextState>,
  document.getElementById("root")
);

serviceWorker.unregister();

import React, { Children, useReducer } from "react";
import Context from "../helper/context";
import * as authActions from "./actions/authActions";
import * as authReducer from "./reducers/authReducer";

const ContextState = ({ children }) => {
  const [stateAuthReducer, dispatchAuthReducer] = useReducer(
    authReducer.AuthReducer,
    {
      ...authReducer.initialState,
      isAuth: localStorage.getItem("@MeuProverSaude/token") !== null,
      token: localStorage.getItem("@MeuProverSaude/token") || "",
    }
  );

  const handleLogin = (data) => {
    dispatchAuthReducer(authActions.login(data)); 
  };

  const handleLogout = () => {
    dispatchAuthReducer(authActions.logout());
  };

  return (
    <Context.Provider
      value={{
        authState: stateAuthReducer.isAuth,
        usernameState: stateAuthReducer.username,
        tokenState: stateAuthReducer.token,
        handleUserLogin: (username) => handleLogin(username),
        handleUserLogout: () => handleLogout(),
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ContextState;

import * as ACTION_TYPES from "../actions/authActions";

export const initialState = {
  isAuth: false,
  name: "",
  email: "",
  token: "",
};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN:
      return {
        ...state,
        isAuth: true,
        name: action.name,
        email: action.email,
        token: action.token,
      };
    case ACTION_TYPES.LOGOUT:
      return {
        ...state,
        isAuth: false,
        name: "",
        email: "",
        token: "",
      };
    default:
      return state;
  }
};

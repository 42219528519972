import React from 'react';
import { Button } from "react-bootstrap";
import { createPortal } from 'react-dom';
import useConfirm from './hooks/useConfirm';

const ConfirmDialog = () => {
    const { onConfirm, onCancel, confirmState } = useConfirm();

    const portalElement = document.getElementById('portal');
    const message = confirmState.text.split('\n');
    const component = confirmState.show ? (
        <div className="portal-overlay">
            <div className="confirm-dialog">
                {message.map((e, index) => <h4 className="card-title" key={`message-${index}`} style={{ color: '#000' }}>{confirmState && e}</h4>)}
                <div className="confirm-dialog__footer">
                    <Button variant="primary"
                        style={{ marginRight: 4, minWidth: 80 }}
                        onClick={onConfirm}>
                        SIM
                    </Button>
                    <Button variant="secondary"
                        style={{ marginLeft: 4, minWidth: 80 }}
                        onClick={onCancel}>
                        NÃO
                    </Button>
                </div>
            </div>
        </div>
    ) : null;

    return createPortal(component, portalElement);
};
export default ConfirmDialog;
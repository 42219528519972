import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import { Collapse, Dropdown } from "react-bootstrap";
import { Trans } from "react-i18next";
import jwt_decode from "jwt-decode";
import Context from "../helper/context";

const usePrevLocation = (location) => {
  const prevLocRef = useRef(location);

  useEffect(() => {
    prevLocRef.current = location;
  }, [location]);

  return prevLocRef.current;
};

const Sidebar = () => {
  const [state, setState] = useState({});
  const [profile, setProfile] = useState(0);
  const [agent, setAgent] = useState(0);

  const context = useContext(Context);

  const location = useLocation();
  const prevLocation = usePrevLocation(location);

  const toggleMenuState = (menuState) => {
    if (state[menuState]) {
      setState({ [menuState]: false });
    } else if (Object.keys(state).length === 0) {
      setState({ [menuState]: true });
    } else {
      Object.keys(state).forEach((i) => {
        setState({ [i]: false });
      });
      setState({ [menuState]: true });
    }
  };

  useEffect(() => {
    if (location !== prevLocation) {
      onRouteChanged();
    }
  }, [location]);

  const onRouteChanged = () => {
    // document.querySelector("#sidebar").classList.remove("active");
    // Object.keys(state).forEach((i) => {
    //   setState({ [i]: false });
    // });
    // const dropdownPaths = [
    //   { path: "/apps", state: "appsMenuOpen" },
    //   { path: "/basic-ui", state: "basicUiMenuOpen" },
    //   { path: "/form-elements", state: "formElementsMenuOpen" },
    //   { path: "/tables", state: "tablesMenuOpen" },
    //   { path: "/reports", state: "reportsMenuOpen" },
    //   { path: "/icons", state: "iconsMenuOpen" },
    //   { path: "/charts", state: "chartsMenuOpen" },
    //   { path: "/login", state: "userPagesMenuOpen" },
    //   { path: "/error-pages", state: "errorPagesMenuOpen" },
    // ];
    // dropdownPaths.forEach((obj) => {
    //   if (isPathActive(obj.path)) {
    //     setState({ [obj.state]: true });
    //   }
    // });
  };

  const isPathActive = (path) => {
    const { pathname } = location;
    return pathname === path;
  };

  useEffect(() => {
    if (context.tokenState) {
      var decoded = jwt_decode(context.tokenState);
      setProfile(decoded.pfl || 0);
      setAgent(parseInt(decoded.ag) || 0);
    }

    onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }, []);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
        <a className="sidebar-brand brand-logo" href="index.html">
          <img
            style={{ width: "16rem", height: "3.5rem" }}
            src={require("../../assets/images/logo_prover_saude.png")}
            alt="logo"
          />
        </a>
        <a className="sidebar-brand brand-logo-mini" href="index.html">
          <img
            style={{ width: "4rem", height: "1.25rem" }}
            src={require("../../assets/images/logo_prover_saude.png")}
            alt="logo"
          />
        </a>
      </div>
      <ul className="nav">
        {/*<li className="nav-item profile">
          <div className="profile-desc">
            <div className="profile-pic">
              <div className="count-indicator">
                <img
                  className="img-xs rounded-circle "
                  src={require("../../assets/images/faces/face15.jpg")}
                  alt="profile"
                />
                <span className="count bg-success"></span>
              </div>
              <div className="profile-name">
                <h5 className="mb-0 font-weight-normal">
                  <Trans>Meu Prover Saúde</Trans>
                </h5>
                <span>
                  <Trans>Suporte</Trans>
                </span>
              </div>
            </div>
            
            <Dropdown alignRight>
              <Dropdown.Toggle as="a" className="cursor-pointer no-caret">
                <i className="mdi mdi-dots-vertical"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="sidebar-dropdown preview-list">
                <a
                  href="!#"
                  className="dropdown-item preview-item"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-settings text-primary"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject ellipsis mb-1 text-small">
                      <Trans>Account settings</Trans>
                    </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a
                  href="!#"
                  className="dropdown-item preview-item"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-onepassword  text-info"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject ellipsis mb-1 text-small">
                      <Trans>Change Password</Trans>
                    </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a
                  href="!#"
                  className="dropdown-item preview-item"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-calendar-today text-success"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject ellipsis mb-1 text-small">
                      <Trans>To-do list</Trans>
                    </p>
                  </div>
                </a>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </li>*/}
        <li className="nav-item nav-category">
          <span className="nav-link">
            <Trans>Navegação</Trans>
          </span>
        </li>

        {[0].includes(profile) && (
          <li
            className={
              isPathActive("/dashboard")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/dashboard">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">
                <Trans>Dashboard</Trans>
              </span>
            </Link>
          </li>
        )}

        {[0, 2, 3].includes(profile) && (
          <li
            className={
              isPathActive("/sales-customer")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/sales-customer">
              <span className="menu-icon">
                <i className="mdi mdi-table-large"></i>
              </span>
              <span className="menu-title">
                <Trans>Contratos</Trans>
              </span>
            </Link>
          </li>
        )}

        {[0, 1].includes(profile) && (
          <li
            className={
              isPathActive("/dashboard-analysis")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/dashboard-analysis">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">
                <Trans>Contratos Análise</Trans>
              </span>
            </Link>
          </li>
        )}

        {[0, 1].includes(profile) && (
          <li
            className={
              isPathActive("/dashboard-analyzed")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/dashboard-analyzed">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">
                <Trans>Contratos Analisados</Trans>
              </span>
            </Link>
          </li>
        )}

        {[0, 1].includes(profile) && agent === 1 && (
          <li
            className={
              isPathActive("/dashboard-refused")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/dashboard-refused">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">
                <Trans>Contratos Recusados</Trans>
              </span>
            </Link>
          </li>
        )}

        {agent === 1 && (
          <li
            className={
              isPathActive("/dashboard-finance")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/dashboard-finance">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">
                <Trans>Financeiro Pendências</Trans>
              </span>
            </Link>
          </li>
        )}

        {[0].includes(profile) && agent === 1 && (
          <li
            className={
              isPathActive("/dashboard-calls")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/dashboard-calls">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">
                <Trans>Meus Atendimentos</Trans>
              </span>
            </Link>
          </li>
        )}

        {[0].includes(profile) && agent === 1 && (
          <li
            className={
              isPathActive("/accredited")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/accredited">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">
                <Trans>Credenciados</Trans>
              </span>
            </Link>
          </li>
        )}

        {[0].includes(profile) && agent === 1 && (
          <li
            className={
              isPathActive("/advantage-clubs")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/advantage-clubs">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">
                <Trans>Clube de Vantagens</Trans>
              </span>
            </Link>
          </li>
        )}

        {[0].includes(profile) && agent === 1 && (
          <li
            className={
              isPathActive("/health-tips")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/health-tips">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">
                <Trans>Dicas de Saúde</Trans>
              </span>
            </Link>
          </li>
        )}

        {[0].includes(profile) && agent === 1 && (
          <li
            className={
              isPathActive("/plan-modality")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/plan-modality">
              <span className="menu-icon">
                <i className="mdi mdi-table-large"></i>
              </span>
              <span className="menu-title">
                <Trans>Modalidades Plano</Trans>
              </span>
            </Link>
          </li>
        )}

        {/*<li
          className={
            isPathActive("/basic-ui")
              ? "nav-item menu-items active"
              : "nav-item menu-items"
          }
        >
          <div
            className={
              state.basicUiMenuOpen
                ? "nav-link menu-expanded"
                : "nav-link"
            }
            onClick={() => toggleMenuState("basicUiMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-icon">
              <i className="mdi mdi-laptop"></i>
            </span>
            <span className="menu-title">
              <Trans>Basic UI Elements</Trans>
            </span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.basicUiMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/basic-ui/buttons")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/basic-ui/buttons"
                  >
                    <Trans>Buttons</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/basic-ui/dropdowns")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/basic-ui/dropdowns"
                  >
                    <Trans>Dropdowns</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/basic-ui/typography")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/basic-ui/typography"
                  >
                    <Trans>Typography</Trans>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
                  </li> */}
       {![4].includes(profile) && (<li
          className={
            isPathActive("/reports")
              ? "nav-item menu-items active"
              : "nav-item menu-items"
          }
        >
          <div
            className={
              state.reportsMenuOpen ? "nav-link menu-expanded" : "nav-link"
            }
            onClick={() => toggleMenuState("reportsMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-icon">
              <i className="mdi mdi-table-large"></i>
            </span>
            <span className="menu-title">
              <Trans>Relatórios</Trans>
            </span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.reportsMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                {[0].includes(profile) && (<li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/reports-new-sales")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/reports-new-sales"
                  >
                    <Trans>Contratos Cadastrados</Trans>
                  </Link>
                </li>)}

                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/reports-sales")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/reports-sales"
                  >
                    <Trans>Vendas por Período</Trans>
                  </Link>
                </li>

                {[0].includes(profile) && (<li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/reports-group-sales")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/reports-group-sales"
                  >
                    <Trans>Contratos Implantados</Trans>
                  </Link>
                </li>)}
                {[0].includes(profile) && (<li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/reports-payments")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/reports-payments"
                  >
                    <Trans>Movimento de Caixa</Trans>
                  </Link>
                </li>)}
              </ul>
            </div>
          </Collapse>
        </li>)}

        {[0].includes(profile) && agent === 1 && (
          <li
            className={
              isPathActive("/tables")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                state.tablesMenuOpen ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => toggleMenuState("tablesMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-table-large"></i>
              </span>
              <span className="menu-title">
                <Trans>Usuários</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={state.tablesMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        isPathActive("/user-mobile")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/user-mobile"
                    >
                      <Trans>Meu Prover Saúde</Trans>
                    </Link>
                  </li>
                  {[0].includes(profile) && agent === 1 && (
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          isPathActive("/users-platform")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/users-platform"
                      >
                        <Trans>Plataforma</Trans>
                      </Link>
                    </li>
                  )}
                  {[0].includes(profile) && agent === 1 && (
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          isPathActive("/companies")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/companies"
                      >
                        <Trans>Empresas</Trans>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </Collapse>
          </li>
        )}

        {/*
        <li
          className={
            isPathActive("/form-elements")
              ? "nav-item menu-items active"
              : "nav-item menu-items"
          }
        >
          <div
            className={
              state.formElementsMenuOpen
                ? "nav-link menu-expanded"
                : "nav-link"
            }
            onClick={() => toggleMenuState("formElementsMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-icon">
              <i className="mdi mdi-playlist-play"></i>
            </span>
            <span className="menu-title">
              <Trans>Form Elements</Trans>
            </span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.formElementsMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/form-elements/basic-elements")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/form-elements/basic-elements"
                  >
                    <Trans>Basic Elements</Trans>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
                  </li>*/}

        {/*<li
          className={
            isPathActive("/charts")
              ? "nav-item menu-items active"
              : "nav-item menu-items"
          }
        >
          <div
            className={
              state.chartsMenuOpen
                ? "nav-link menu-expanded"
                : "nav-link"
            }
            onClick={() => toggleMenuState("chartsMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-icon">
              <i className="mdi mdi-chart-bar"></i>
            </span>
            <span className="menu-title">
              <Trans>Charts</Trans>
            </span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.chartsMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/charts/chart-js")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/charts/chart-js"
                  >
                    <Trans>Chart Js</Trans>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
                  </li>
        <li
          className={
            isPathActive("/icons")
              ? "nav-item menu-items active"
              : "nav-item menu-items"
          }
        >
          <div
            className={
              state.iconsMenuOpen ? "nav-link menu-expanded" : "nav-link"
            }
            onClick={() => toggleMenuState("iconsMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-icon">
              <i className="mdi mdi-contacts"></i>
            </span>
            <span className="menu-title">
              <Trans>Icons</Trans>
            </span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.iconsMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/icons/mdi")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/icons/mdi"
                  >
                    <Trans>Material</Trans>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
        <li
          className={
            isPathActive("/login")
              ? "nav-item menu-items active"
              : "nav-item menu-items"
          }
        >
          <div
            className={
              state.userPagesMenuOpen
                ? "nav-link menu-expanded"
                : "nav-link"
            }
            onClick={() => toggleMenuState("userPagesMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-icon">
              <i className="mdi mdi-security"></i>
            </span>
            <span className="menu-title">
              <Trans>User Pages</Trans>
            </span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.userPagesMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/login/login-1")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/login/login-1"
                  >
                    <Trans>Login</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/login/register-1")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/login/register-1"
                  >
                    <Trans>Register</Trans>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>*/}
        {/*
        <li className="nav-item nav-category">
          <span className="nav-link">
            <Trans>More</Trans>
          </span>
        </li>
       
        <li
          className={
            isPathActive("/error-pages")
              ? "nav-item menu-items active"
              : "nav-item menu-items"
          }
        >
          <div
            className={
              state.errorPagesMenuOpen
                ? "nav-link menu-expanded"
                : "nav-link"
            }
            onClick={() => toggleMenuState("errorPagesMenuOpen")}
            data-toggle="collapse"
          >
            <span className="menu-icon">
              <i className="mdi mdi-lock"></i>
            </span>
            <span className="menu-title">
              <Trans>Error Pages</Trans>
            </span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.errorPagesMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/error-pages/error-404")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/error-pages/error-404"
                  >
                    404
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/error-pages/error-500")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/error-pages/error-500"
                  >
                    500
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li> 
                  */}
        {/*<li className="nav-item menu-items">
          <a
            className="nav-link"
            href="http://bootstrapdash.com/demo/corona-react-free/documentation/documentation.html"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="menu-icon">
              <i className="mdi mdi-file-document-box"></i>
            </span>
            <span className="menu-title">
              <Trans>Documentation</Trans>
            </span>
          </a>
        </li>*/}
      </ul>
    </nav>
  );
};

export default withRouter(Sidebar);

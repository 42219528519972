import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "../app/shared/Spinner";
import PrivateRoute from "./helper/PrivateRoute";
import PublicRoute from "./helper/PublicRoute";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const DashboardCalls = lazy(() => import("./dashboard/contracts"));
const DashboardAnalysis = lazy(() => import("./dashboard/analysis"));
const DashboardAnalyzed = lazy(() => import("./dashboard/analyzed"));
const DashboardRefused = lazy(() => import("./dashboard/refused"));
const FinancialPending = lazy(() => import("./dashboard/financialPending"));
const ContractSignature = lazy(() => import("./forms/signature/ContractSignature"));
// ----------------------------------------------------------------------------------
const ReportSalesContracts = lazy(() => import("./reports/salesContracts"));
const ReportGroupSalesContracts = lazy(() => import("./reports/salesGroupContracts"));
const ReportNewSalesContracts = lazy(() => import("./reports/salesNewContracts"));
const ReportPayments = lazy(() => import("./reports/reportPayments"));
// ----------------------------------------------------------------------------------
const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));
const Typography = lazy(() => import("./basic-ui/Typography"));

const BasicElements = lazy(() => import("./form-elements/BasicElements"));

const AdvantageClubs = lazy(() => import("./advantageClubs/AdvantageClubs"));
const HealthTips = lazy(() => import("./forms/healthTips/HealthTips"));
const Accredited = lazy(() => import("./forms/accredited/Accredited"));

const PlanModality = lazy(() => import("./forms/planModality/PlanModality"));

const UserMobile = lazy(() => import("./userMobile/UserMobile"));
const UsersPlatform = lazy(() => import("./usersPlatform/UsersPlatform"));
const Companies = lazy(() => import("./company/Company"));

const Customer = lazy(() => import("./sales/customer/Customer"));

const BasicTable = lazy(() => import("./tables/BasicTable"));

const Mdi = lazy(() => import("./icons/Mdi"));

const ChartJs = lazy(() => import("./charts/ChartJs"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const Login = lazy(() => import("./login/Login"));
const Register1 = lazy(() => import("./login/Register"));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute
            exact
            path="/dashboard-calls"
            component={DashboardCalls}
          />
          <PrivateRoute
            exact
            path="/dashboard-finance"
            component={FinancialPending}
          />
          <PrivateRoute
            exact
            path="/dashboard-analysis"
            component={DashboardAnalysis}
          />
          <PrivateRoute
            exact
            path="/dashboard-analyzed"
            component={DashboardAnalyzed}
          />
          <PrivateRoute
            exact
            path="/dashboard-refused"
            component={DashboardRefused}
          />
          <PrivateRoute
            exact
            path="/reports-sales"
            component={ReportSalesContracts}
          />
          <PrivateRoute
            exact
            path="/reports-group-sales"
            component={ReportGroupSalesContracts}
          />
          <PrivateRoute
            exact
            path="/reports-new-sales"
            component={ReportNewSalesContracts}
          />
          <PrivateRoute
            exact
            path="/reports-payments"
            component={ReportPayments}
          />
          <PrivateRoute path="/advantage-clubs" component={AdvantageClubs} />
          <PrivateRoute path="/health-tips" component={HealthTips} />
          <PrivateRoute path="/accredited" component={Accredited} />
          <PrivateRoute path="/plan-modality" component={PlanModality} />
          <PrivateRoute path="/user-mobile" component={UserMobile} />
          <PrivateRoute path="/sales-customer" component={Customer} />
          <PrivateRoute path="/contract-signature" component={ContractSignature} />

          <PrivateRoute path="/users-platform" component={UsersPlatform} />
          <PrivateRoute path="/companies" component={Companies} />
          <PrivateRoute path="/basic-ui/buttons" component={Buttons} />
          <PrivateRoute path="/basic-ui/dropdowns" component={Dropdowns} />
          <PrivateRoute path="/basic-ui/typography" component={Typography} />
          <PrivateRoute
            path="/form-Elements/basic-elements"
            component={BasicElements}
          />
          <PrivateRoute path="/tables/basic-table" component={BasicTable} />
          <PrivateRoute path="/icons/mdi" component={Mdi} />
          <PrivateRoute path="/charts/chart-js" component={ChartJs} />
          <PublicRoute path="/login/login-1" component={Login} />
          <PrivateRoute path="/login/register-1" component={Register1} />
          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} />
          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;

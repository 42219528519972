import React, { useContext, useEffect, useState } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import Context from "./context";

function PublicRoute(props) {
  const context = useContext(Context);
  const { component: Component, restricted = false, ...rest } = props;
  const render = (props) => {
    if (context.authState) {
      return <Redirect to={"/dashboard"} />;
    }
    return <Component {...props} />;
  };

  return <Route {...rest} render={render} />;
}

export default PublicRoute;
